import React, { useCallback, useMemo, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./rolling-stock.css"
import "./catalog.css"

type FiguresPageProps = {
  data: {
    content: {
      pageSections: Array<{
        sectionName: string
        sectionItems: Array<{
          price: string
          name: string
          description: string
          tags: Array<string>
          image: {
            asset: {
              url: string
              title: string
            }
          }
        }>
      }>
    } | null
  }
}

const FilterCheckbox = ({
  onChange,
  value,
  checked,
  children,
}: {
  onChange: (string) => void
  value: string
  checked: boolean
  children: any
}) => {
  return (
    <div style={{display:'flex', alignItems:'center'}}>
      <input
        onInput={() => onChange(value)}
        checked={checked}
        id={value}
        name={value}
        type="checkbox"
      ></input>
      <label htmlFor={value}>{children}</label>
    </div>
  )
}

const FiguresPage = ({ data }: FiguresPageProps) => {
  const [filters, setFilters] = useState<Array<string>>(["child"])

  const pageSection = data?.content?.pageSections[0]

  const filteredResults = useMemo(
    () =>
      pageSection?.sectionItems.filter(sectionItem =>
        filters.every(f => sectionItem.tags.includes(f))
      ) ?? [],
    [filters]
  )

  const handleFilterChanged = useCallback((value) => {
    if (filters.includes(value)) {
      // if it's in the filters, remove it
      setFilters(f => f.filter(f0 => f0 !== value));
    } else {
      // if it's not, add it
      setFilters(f => [...f, value]);
    }
  }, [filters]);

  return (
    <Layout>
      <SEO title="Home" />
      <div className="container" style={{ flex: 1 }}>
        <p>
          Creative Trains offers a wide collection of 1:8 scale 3D-printed Figures that
          you can choose from to make your scenery come to life!
        </p>
        <p>
          All 3D-printed figures can be painted custom (or unpainted)
        </p>
        <div className="figures">
          <div className="figures__filter-box">
            <div style={{ fontWeight: "bold" }}>Filters</div>
            <FilterCheckbox value="adult" checked={filters.includes("adult")} onChange={handleFilterChanged}>Adults</FilterCheckbox>
            <FilterCheckbox value="child" checked={filters.includes("child")} onChange={handleFilterChanged}>Children</FilterCheckbox>
            <FilterCheckbox value="sitting" checked={filters.includes("sitting")} onChange={handleFilterChanged}>Sitting</FilterCheckbox>
            <FilterCheckbox value="standing" checked={filters.includes("standing")} onChange={handleFilterChanged}>Standing</FilterCheckbox>
            <FilterCheckbox value="male" checked={filters.includes("male")} onChange={handleFilterChanged}>Man</FilterCheckbox>
            <FilterCheckbox value="female" checked={filters.includes("female")} onChange={handleFilterChanged}>Woman</FilterCheckbox>
            <FilterCheckbox value="construction" checked={filters.includes("construction")} onChange={handleFilterChanged}>Construction</FilterCheckbox>
          </div>
          <div className="figures__figure-list">
            <section>
              {filteredResults.length ? filteredResults.map(sectionItem => (
                <div className="figures__item">
                  <img src={sectionItem.image?.asset?.url} />
                  <div className="figures__item-contents">
                    <div className="figures__item-name">{sectionItem.name}</div>
                    <div className="figures__item-description">
                      {sectionItem.description}
                    </div>
                    {/* <div className="figures__item-price">
                      {sectionItem.price}
                    </div> */}
                  </div>
                </div>
              )) : <div>No figures match your filters</div>}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

/**
 * seated/standing
 * male/female
 * child/adult
 * single/pair
 */

export default FiguresPage

export const query = graphql`
  query FiguresPageQuery {
    content: sanityCatalogContent(pageId: { current: { eq: "figures" } }) {
      pageSections {
        sectionItems {
          price
          name
          image {
            asset {
              url
              title
            }
          }
          description
          tags
        }
        sectionName
      }
    }
  }
`
